import { mapActions } from 'vuex'

export default {
    name: 'AccountIndex',
    data: () => ({
        model: {},
        loading: false,
        message: '',
        error: false,
        errors: {},
    }),
    watch: {

        message(message){
            if(message.length > 0 && !this.error) {
                setTimeout(()=>{
                    this.$router.push({ name: 'login' });
                }, 3000)
            }
        }
        
    },
    methods: {
        ...mapActions('account', ['ActionSave']),
        save() {
            
            this.loading = true
            this.message = ''
            this.error = true
            this.errors = {}
            
            this.ActionSave(this.model)
                .then((res) => {
                    this.model = {}
                    this.message = res.message
                    this.error = false
                })
                .catch((error) => {
                    this.error = true
                    this.message = error.message
                    if(error.code === 422) {
                        this.errors = error.errors
                    } else {
                       
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
}